.login{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Arial, sans-serif;
}
.login-box{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, .25);
}
.login-logo{
    height: 100px;
}
.m-label{
    font-size: 35px;
    margin-bottom: 60px;
    display: none;
}
.login-info{
    display: flex;
    flex-direction: column;
}

.login-info > label{
    margin: 20px 0;
    font-size: 20px;
}

.login-info > input{
    background-color: transparent;
    border: none;
    border-bottom: 3px solid var(--red);;
    outline: none;
    padding: 3px;
    font-size: 20px;
    color: black;
    transition: border-color .2s ease-in-out, box-shadow .2s ease-in-out;
}

.login-info > input:focus{
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, .25);
}
#login{
    border: none;
    outline: none;
    background-color: var(--red);
    color: white;
    padding: 7px 20px;
    border-radius: 20px;
    font-size: 25px;
    margin-top: 50px;
    transition: background-color .2s ease-in-out;
}

#login:hover{
    background-color: var(--reder);
    cursor: pointer;
}

.login-failed{
    opacity: 0;
    transition: opacity .1s ease-in-out;
    display: flex;
    width: 200px;
    justify-content: space-between;
    margin-top: 20px;
    font-size: 19px;
    justify-content: center;
    text-align: center;
}
.addOpacity{
    opacity: 1;
}
.login-check{
    align-self: center;
    display: flex;
    align-items: center;
    margin-top: 30px;
    font-size: 16px;
    cursor: pointer;
    user-select: none;
    font-size: 17px;
}
.check{
    height: 20px;
    width: 20px;
    border-radius: 4px;
    border: 1.5px solid var(--red);
    background-color: var(--red);
    background-image: url(../assets/check.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 25px;
    margin-left: 5px;
    transition: background-color .2s ease-in-out;
}
.not-checked{
    background-color: unset;
}
@media screen and (min-width: 650px) {
    .login-box{
        height: 580px;
        width: 500px;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        border-radius: 8px;
    }
}