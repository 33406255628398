.hide-tab{
    display: none !important;
}

.day-view-btn{
    background-color: #EE1D23;
    border: none;
    height: 70px;
    width: 70px;
    background-size: 40px;
    background-image: url('../assets/back.svg');
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.day-view-info{
    height: 105px;
    background-image: linear-gradient(to right, #5700ec, #413f44);
    margin-top: -12px;
    padding-top: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 30px;
}

.day-view-day{
    font-size: 45px;
}
.day-view-date{
    font-size: 15px;
    margin-top: -5px;
}

.day-edit{
    position: absolute;
    background-color: transparent;
    left: 40px;
    border: none;
    border-radius: 50%;
    height: 70px;
    width: 70px;
    background-size: 40px;
    background-image: url('../assets/edit.svg');
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    transition: background-image .2s ease-in-out;
}
.day-edit::after{
    content: 'ערוך';
    position: absolute;
    color: white;
    font-size: 15px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

}
.day-save{
    background-image: url('../assets/save.svg');
}
.day-save::after{
    content: 'שמור';
}
.edit-icon{
    position: absolute;
    top: 0;
    right: 0;
}
.day-tabs{
    width: 100%;
    display: flex;
    overflow: auto;
    margin: auto;
}
.day-tab-btn{
    background-color: #f6f6f4;
    color: #878787;
    border: none;
    font-size: 20px;
    padding: 4px 10px;
    cursor: pointer;
    white-space: nowrap;
    width: 150px;
    padding-bottom: 3px;
}

.active-tab{
    background-color: #dfe0e2;
    color: black;
    background-image: linear-gradient(to right, #f89191, #f89191);
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: 70% 2px;
}
.day-tab{
    background-color: #dfe0e2;
}

.tab-part{
    background-color: #c5c5c5;
    width: 100%;
    height: 10px;
    margin: 10px 0;
}

.day-item{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.day-label{
    background-image: linear-gradient(to right, #f89191, #f89191);
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: 70% 2px;
    padding-bottom: 5px;
    font-weight: bold;
}
.day-info{
    border: none;
    background-image: linear-gradient(to right, #dfe0e2, #bfbfbf, #dfe0e2);
    text-align: center;
    font-size: 25px;
    padding: 3px 5px;
    width: 200px;
    height: 30px;
    border-radius: 4px;
}
input.day-info{
    direction: ltr;
}
.info-edit{
    background-image: none;
    background-color: white;
}
.tab-chart{
    padding-top: 10px;
    background-color: #dfe0e2;
}
@media screen and (min-width: 600px) {
    .day-tabs{
        justify-content: center;
    }
    .day-tab{
        padding: 25px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .day-item{
        margin: 0 10px;
    }
}