.menu{
    height: 100vh;
    width: 100vw;
    z-index: 9;
    background-color: rgba(128, 128, 128, 0.3);
    position: fixed;
    top: 0;
    display: none;
}
.display-menu{
    display: block;
}
.menu > div{
    background-color: white;
    height: 100vh;
    width: 70%;
    position: relative;
    margin-top: 68px;
}
.menu-title{
    text-align: center;
    padding-top: 20px;
}
.menu span{
    font-weight: normal;
    font-size: 30px;
    padding-bottom: 4px;

    background-image: linear-gradient(to right, #cecece, #cecece);
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: 95% 2px;
}

.branches{
    list-style: none;
    padding: 20px;
    height: 60vh;
    overflow: auto;
    padding-bottom: 50px;
    margin: 0;
}
.branch-li{
    display: flex;
    align-items: center;
}
.branch-li > img{
    margin-left: 3px;
}
.branch-li > button{
    background: none;
    border: none;
    font-size: 18px;
    margin: 4px 0;
    padding: 4px 0;
    cursor: pointer;

    background-image: linear-gradient(to right, #f89191, #f89191);
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: 95% 2px;
}

.logout{
    width: 100%;
    background: linear-gradient(to right, #fec1c1, #c5c5c5);
    height: 35px;
    text-align: left;
}

.logout > button{
    background: none;
    border: none;
    cursor: pointer;
    height: 35px;
    font-size: 18px;
    margin-left: 10px;
    background-image: url('../assets/logout.png');
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: right;
    text-align: left;
    width: 105px;
}

@media screen and (min-width: 650px){
    .menu > div{
        width: 455px;
    }
    .logout{
        position: absolute;
        bottom: 20%;
    }
}