.income-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.income-line-chart{
    position: relative;
    width: 90%;
    margin-bottom: 15px;
}

.income-table{
  border-collapse: collapse;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

/* .income-table th, .income-table td{
    border-bottom: solid #f13239 1px;
    border-top: solid #f13239 1px;
    
} */

.income-table th{
    width: 130px;
    font-weight: normal;
    font-size: 18px;
}
.income-table td{
    width: 200px;
    font-size: 25px;
    text-align: center;
}
.income-precent{
    font-weight: bold;
    background-color: #f13239;
    color: white;
}

@media screen and (min-width: 450px) {
    .income-line-chart{
        width: 400px;
    }

}

@media screen and (min-width: 900px) {

    .income-div{
        justify-content: space-evenly;
        flex-direction: row;
    }
}